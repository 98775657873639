import React from "react";
import "./skills.css";
import TherapySkillsLeft from "./TherapySkillsLeft";
import TherapySkillsRight from "./TherapySkillsRight";

const Specialties = () => {
  return (
    <section className='skills section' id='specialties'>
      <h2 className='section_title'>Specialties</h2>
      {/*<span className='section_subtitle'>My specialties</span>*/}

      <div className='skills_container container grid'>
        <TherapySkillsLeft/>
        <TherapySkillsRight/>
      </div>
    </section>
  );
};

export default Specialties;
