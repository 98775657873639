import React, {useEffect, useRef, useState} from "react";
import "./header.css";

const Header = () => {
  const [toggle, setToggle] = useState(false);
  const navbarRef = useRef(null);
  const closeOpenNavBar = (e) => {
    console.log(e);
    if(navbarRef.current && toggle && !navbarRef.current.contains(e.target)) {
      setToggle(false);
    }
  };
  useEffect(() => {
    navbarRef.current.addEventListener('mousedown', closeOpenNavBar);
  }, []);

  return (
    <header className="header">
      <nav className="nav container" ref={navbarRef}>
        <a href="#home" className="nav_logo">Koh Woon Kim, PhD</a>

        <div className={toggle ? "nav_menu show_menu" : "nav_menu"}>
          <ul className="nav_list grid">
            <li className="nav_item">
              <a href="#home" className="nav_link" onClick={() => setToggle(!toggle)}>
                <i className="uil uil-estate nav_icon active-link"/>
                Home
              </a>
            </li>

            <li className="nav_item">
              <a href="#about" className="nav_link" onClick={() => setToggle(!toggle)}>
                <i className="uil uil-user nav_icon"></i>
                About
              </a>
            </li>

            <li className="nav_item">
              <a href="#specialties" className="nav_link" onClick={() => setToggle(!toggle)}>
                <i className="uil uil-file-alt nav_icon"></i>
                Specialties
              </a>
            </li>

            <li className="nav_item">
              <a href="#services" className="nav_link" onClick={() => setToggle(!toggle)}>
                <i className="uil uil-briefcase-alt nav_icon"/>
                Services
              </a>
            </li>

            {/*<li className="nav_item">*/}
            {/*  <a href="#helpfulInformation" className="nav_link" onClick={() => setToggle(!toggle)}>*/}
            {/*    <i className="uil uil-message nav_icon"/>*/}
            {/*    Helpful Information*/}
            {/*  </a>*/}
            {/*</li>*/}

            {/*<li className="nav_item">*/}
            {/*  <a href="#qualification" className="nav_link">*/}
            {/*    <i className="uil uil-scenery nav_icon"/>*/}
            {/*    Qualification*/}
            {/*  </a>*/}
            {/*</li>*/}

            {/*<li className="nav_item">*/}
            {/*  <a href="#faqs" className="nav_link">*/}
            {/*    <i className="uil uil-scenery nav_icon"/>*/}
            {/*    FAQs*/}
            {/*  </a>*/}
            {/*</li>*/}

            <li className="nav_item">
              <a href="#contact" className="nav_link" onClick={() => setToggle(!toggle)}>
                <i className="uil uil-message nav_icon"/>
                Contact
              </a>
            </li>
          </ul>
          <i className="uil uil-times nav_close" onClick={() => setToggle(!toggle)}/>
        </div>

        <div className="nav_toggle" onClick={() => setToggle(!toggle)}>
          <i className="uil uil-apps"/>
        </div>
      </nav>
    </header>
  );
};

export default Header;
