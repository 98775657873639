import React from "react";

const socialData = [
  {
    name: 'instagram',
    iconName: 'uil uil-instagram',
    link: 'https://www.instagram.com/kohwoonkim'
  },
  {
    name: 'phone',
    iconName: 'uil uil-phone',
    link: 'tel:+19174806454'
  },
  {
    name: 'email',
    iconName: 'uil uil-envelope-alt',
    link: 'mailto:kohwoon.kim@gmail.com'
  }
];

const Social = () => {
  return (
    <div className="home_social">
      {socialData.map(social => (
        <a href={social.link} className="home_social-icon" target="_blank" key={social.name}>
          <i className={social.iconName} />
        </a>
      ))}
    </div>
  );
};

export default Social;
