import React from "react";

const infoData = [
  {
    iconName: "bx bx-award about_icon",
    title: "R-DMT Certified",
    subtitle: ""
  },
  {
    iconName: "bx bx-heart about_icon",
    title: "Relationship Centered",
    subtitle: ""
  },
  {
    iconName: 'bx bx-check-shield about_icon',
    title: "Support",
    subtitle: "Online/In Person"
  }
];

const info = () => {
  return (
    <div className='about_info grid'>
      {infoData.map(info => (
        <div className='about_box'>
          <i className={info.iconName}/>

          <h3 className='about_title'>{info.title}</h3>
          <span className='about_subtitle'>{info.subtitle}</span>
        </div>
      ))}
    </div>
  );
};

export default info;
