import React from "react";
import "./services.css";
import {useState} from "react";

const Services = () => {
  const [toggleState, setToggleState] = useState(0);

  const toggleTab = (index) => {
    setToggleState(index);
  };

  return (
    <section className='services section' id='services'>
      <h2 className='section_title'>Services</h2>
      <span className='section_subtitle'>What I offer</span>

      <div className='services_container container grid'>

        <div className='services_content'>
          <div>
            <i className='uil uil-users-alt services_icon'/>
            <h3 className='services_title'>
              Dance Movement <br/> Therapy
            </h3>
          </div>

          <span onClick={() => toggleTab(1)} className='services_button'> View more{" "}
            <i className='uil uil-arrow-right services_button-icon'>

                </i>
                </span>

          <div className={toggleState === 1 ? "services_modal active-modal" : "services_modal"}>
            <div className='services_modal-content'>
              <i onClick={() => toggleTab(0)} className='uil uil-times services_modal-close'/>

              <h3 className='services_modal-title'>Dance Movement Therapy</h3>
              <p className='services_modal-description'>
                Dance/movement therapy (DMT), is a type of therapy that uses movement to help individuals achieve
                emotional, cognitive, physical, and social integration.
              </p>

              <ul className='services_modal-services grid'>
                <li className='services_modal-service'>
                  <i className='uil uil-check-circle services_modal-icon'/>
                  <p className='services_modal-info'>Movement is a language, our first language.</p>
                </li>
              </ul>

              <ul className='services_modal-services grid'>
                <li className='services_modal-service'>
                  <i className='uil uil-check-circle services_modal-icon'/>
                  <p className='services_modal-info'>Mind, body, and spirit are interconnected.</p>
                </li>
              </ul>

              <ul className='services_modal-services grid'>
                <li className='services_modal-service'>
                  <i className='uil uil-check-circle services_modal-icon'/>
                  <p className='services_modal-info'>Movement can be functional, communicative, developmental, and
                    expressive.</p>
                </li>
              </ul>

              <ul className='services_modal-services grid'>
                <li className='services_modal-service'>
                  <i className='uil uil-check-circle services_modal-icon'/>
                  <p className='services_modal-info'>Observe, assess, and intervene by looking at movement in the
                    therapeutic relationship during the session.</p>
                </li>
              </ul>

              <ul className='services_modal-services grid'>
                <li className='services_modal-service'>
                  <i className='uil uil-check-circle services_modal-icon'/>
                  <p className='services_modal-info'>Movement is both an assessment tool and a primary mode of
                    intervention.</p>
                </li>
              </ul>

            </div>
          </div>
        </div>

        <div className='services_content'>
          <div>
            <i className='uil uil-yin-yang services_icon'/>
            <h3 className='services_title'>
              Gimoo <br/> Therapy
            </h3>
          </div>

          <span onClick={() => toggleTab(2)} className='services_button'>View more{" "}
            <i className='uil uil-arrow-right services_button-icon'>

                </i>
                </span>

          <div className={toggleState === 2 ? "services_modal active-modal" : "services_modal"}>
            <div className='services_modal-content'>
              <i onClick={() => toggleTab(0)} className='uil uil-times services_modal-close'/>

              <h3 className='services_modal-title'>Gimoo Therapy</h3>
              <p className='services_modal-description'>
                A health reinforcement dance originating from the combination of Traditional Korean Dance and
                Traditional Korean Medicine.
              </p>

              <ul className='services_modal-services grid'>
                <li className='services_modal-service'>
                  <i className='uil uil-check-circle services_modal-icon'/>
                  <p className='services_modal-info'>A harmonious balance of body and mind.</p>
                </li>
              </ul>

              <ul className='services_modal-services grid'>
                <li className='services_modal-service'>
                  <i className='uil uil-check-circle services_modal-icon'/>
                  <p className='services_modal-info'>Strengthens, restores and balances the state of qi through healthy
                    breathing.</p>
                </li>
              </ul>

              <ul className='services_modal-services grid'>
                <li className='services_modal-service'>
                  <i className='uil uil-check-circle services_modal-icon'/>
                  <p className='services_modal-info'>Harmony of Yin and Yang.</p>
                </li>
              </ul>

              <ul className='services_modal-services grid'>
                <li className='services_modal-service'>
                  <i className='uil uil-check-circle services_modal-icon'/>
                  <p className='services_modal-info'>Circulation of Qi.</p>
                </li>
              </ul>

              <ul className='services_modal-services grid'>
                <li className='services_modal-service'>
                  <i className='uil uil-check-circle services_modal-icon'/>
                  <p className='services_modal-info'>Regulation of emotions and increased resilience for a peaceful of
                    mind.</p>
                </li>
              </ul>

            </div>
          </div>
        </div>

        <div className='services_content'>
          <div>
            <i className='uil uil-desktop services_icon'/>
            <h3 className='services_title'>
              Virtual <br/> Consultation
            </h3>
          </div>

          <span onClick={() => toggleTab(3)} className='services_button'>View more{" "}
            <i className='uil uil-arrow-right services_button-icon'>

                </i>
                </span>

          <div className={toggleState === 3 ? "services_modal active-modal" : "services_modal"}>
            <div className='services_modal-content'>
              <i onClick={() => toggleTab(0)} className='uil uil-times services_modal-close'/>

              <h3 className='services_modal-title'>Online Help</h3>
              <p className='services_modal-description'>
                Service with more than 3 years of experience.
                Providing quality care to clients and companies.
              </p>

              <ul className='services_modal-services grid'>
                <li className='services_modal-service'>
                  <i className='uil uil-check-circle services_modal-icon'/>
                  <p className='services_modal-info'>Due to COVID-19, all services are also offered online.</p>
                </li>
              </ul>

              <ul className='services_modal-services grid'>
                <li className='services_modal-service'>
                  <i className='uil uil-check-circle services_modal-icon'/>
                  <p className='services_modal-info'>To schedule a free 15 minute consultation, or to inquire about any
                    of my services, please send a message <a href='#contact' style={{color: "blue"}} onClick={() => {setToggleState(false)}}>here</a>.</p>
                </li>
              </ul>

              <ul className='services_modal-services grid'>
                <li className='services_modal-service'>
                  <i className='uil uil-check-circle services_modal-icon'/>
                  <p className='services_modal-info'>Choosing the right therapist based on a webpage can feel daunting,
                    so please don't hesitate to reach out and make a connection. </p>
                </li>
              </ul>

              <ul className='services_modal-services grid'>
                <li className='services_modal-service'>
                  <i className='uil uil-check-circle services_modal-icon'/>
                  <p className='services_modal-info'>I welcome all questions and concerns.</p>
                </li>
              </ul>

            </div>
          </div>
        </div>

      </div>

    </section>

  );
};

export default Services;
