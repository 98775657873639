import React from "react";

const skillsList = [
  "Cognitive Therapy",
  "Trauma",
  "Life Transitions",
  "Attachment Issues",
  "Self-Esteem",
  "Meditation",
  "Mindfulness",
  "Parenting",
  "Energy Healing",
];

const TherapySkillsRight = () => {
  return (
    <div className='skills_content'>
      {/*<h3 className='skills_title'></h3>*/}
      <div className='skills_box'>
        <div className='skills_group'>
          {skillsList.map(skill => (
            <div className='skills_data' key={skill}>
              <i className='bx bx-chevron-right'/>
              <div>
                <h3 className='skills_name'>{skill}</h3>
                <span className='skills_level'></span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TherapySkillsRight;
