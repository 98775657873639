/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react/jsx-no-comment-textnodes */
import React from "react";
import "./footer.css";

const footerListItems = [
  {
    name: "About",
    link: "#about"
  },
  // {
  //   name: "Specialties",
  //   link: "#specialties"
  // },
  {
    name: "Services",
    link: "#services"
  },
  {
    name: "Contact",
    link: "#contact"
  },
];

const socialLinks = [
  {href: "https://www.facebook.com/", iconName: "bx bxl-facebook"},
  {href: "https://www.instagram.com/", iconName: "bx bxl-instagram"},
  {href: "https://www.twitter.com/", iconName: "bx bxl-twitter"},
];
const Footer = () => {
  return (
    <footer className='footer'>
      <div className='footer_container container'>
        {/*<h1 className='footer_title'>Koh Woon Therapy Inc.</h1>*/}

        <ul className='footer_list'>
          {footerListItems.map(item => (
            <li className='list_items'>
              <a href={item.link}
                 className='footer_link'>{item.name}</a>
            </li>
          ))}
        </ul>

        <div className='footer_social'>
          {socialLinks.map(social => (
            <a href={social.href} className='footer_social-link' target='_blank' key={social.iconName}>
              <i className={social.iconName}/>
            </a>
          ))}
        </div>

        <span className='footer_copy'>
                &#169; Koh Woon Therapy. All rights reserved
            </span>
      </div>

    </footer>
  );
};

export default Footer;
