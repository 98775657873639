import "./App.css";
import Header from "./components/header/Header";
import Home from "./components/home/Home";
import About from "./components/about/About";
import Specialties from "./components/specialties/Specialties";
import Services from "./components/services/Services";
import Contact from "./components/contact/Contact";
import Footer from "./components/footer/Footer";
import ScrollUp from "./components/scrollup/ScrollUp";
import Qualification from "./components/qualification/Qualification";
import FrequentlyAsked from "./components/faqs/FrequentlyAsked";

function App() {
  return (
    <>
      <Header/>
      <main className='main'>
        <Home/>
        <About/>
        <Specialties/>
        <Services/>
        {/*<Qualification/>*/}
        {/*<FrequentlyAsked/>*/}
        <Contact/>
      </main>
      <Footer/>
      <ScrollUp/>
    </>
  );
}

export default App;
